import {
    API_URL_STAGE,
    CDN_URL_STAGE,
    REPLICA_URL_STAGE,
    ASSET_MANAGER_STAGE,
    EDITOR_URL_STAGE,
} from '../../shared/types/constants.types';

export const environment = {
    env: 'stage',
    production: true,
    apiUrl: API_URL_STAGE,
    cdnUrl: CDN_URL_STAGE,
    replicaUrl: REPLICA_URL_STAGE,
    assetManagerUrl: ASSET_MANAGER_STAGE,
    spotEditorUrl: EDITOR_URL_STAGE,
};
